import React, {useState} from 'react';
import Adyen from './Adyen';
import TransactionDetails from './TransactionDetails';
import SettlementDetails from './SettlementDetails';
import {Alert, AlertTitle} from "@material-ui/lab";
import {Trans, useTranslation} from "react-i18next";
import {Box, Link} from "@material-ui/core";

function PaymentReportingAdapter({$state}) {
  const {t} = useTranslation();

  const [chosenId, setChosenId] = useState($state.params.id)
  const [trId, setTrId] = useState($state.params.trId)
  const [sortModel, setSortModel] = useState({field: $state.params.orderBy, sort: $state.params.order})
  const [page, setPage] = useState($state.params.page ? ($state.params.page - 1) : 0)

  const onChangeReportType = newValue => {
    if ($state.params.type !== newValue) {
      setSortModel({
        ...{field: undefined, sort: undefined}
      })
    }
    setPage(0);

    $state.go('page.paymentReporting', {
      type: newValue,
      orderBy: ($state.params.type !== newValue ? undefined : $state.params.orderBy),
      order: ($state.params.type !== newValue ? undefined : $state.params.order),
      page: undefined
    }, {notify: false});
  }

  const onChangeReportPeriod = reportPeriod => {
    setPage(0);

    $state.go('page.paymentReporting', {
      periodId: reportPeriod.period.id,
      from: reportPeriod.getPeriod().from,
      to: reportPeriod.getPeriod().to,
      page: undefined
    }, {notify: false});
  }

  const onChangeReportOrdering = ordering => {
    setSortModel({
      ...ordering
    })
    $state.go('page.paymentReporting', {orderBy: ordering?.field, order: ordering?.sort}, {notify: false});
  }

  const onChangeReportPage = page => {
    setPage(page);
    $state.go('page.paymentReporting', {page: page + 1}, {notify: false});
  }

  const goToDetailsPage = ({type, id, trId}) => {
    if (trId) {
      $state.go('page.paymentReportingSettlement', {...$state.params, id: id, trId: trId});
    } else {
      $state.go('page.paymentReporting',
        {type: type || $state.params.type, id: id});
    }
  }

  const goToBillReport = bill => {
    $state.go('page.billReporting',
      {id: 12, bill: bill, r: 98, f: 0});
  }

  const onGoBack = () => {
    $state.go('page.paymentReporting', {
      ...$state.params,
      type: $state.params.trId ? 'settlements' : $state.params.type,
      id: $state.params.trId ? $state.params.id : undefined,
      trId: undefined
    });
  }

  return <>
    {
      <Box mb={3}>
        <Alert severity="warning">
          <AlertTitle>{t('validation.title.warning')}</AlertTitle>
          <Trans
            i18nKey="lsp.dynamicReporting.deprecationWarning"
            components={{
              financialServicesLink: <Link onClick={() => {
                $state.go('page.financialServices');
              }}/>
            }}
          />
        </Alert>
      </Box>
    }
    {
      trId && chosenId &&
      <TransactionDetails id={trId} onGoBack={onGoBack} backText={"settlement " + chosenId} goToBill={goToBillReport}/>
    }
    {
      chosenId && $state.params.type === 'transactions' &&
      <TransactionDetails id={chosenId} onGoBack={onGoBack} goToBill={goToBillReport}/>
    }
    {
      chosenId && $state.params.type === 'settlements' &&
      <SettlementDetails id={chosenId} onGoBack={onGoBack}
                         goToDetails={goToDetailsPage}/>
    }
    {
      !chosenId && <Adyen initialReportType={$state.params.type}
                          onChangeReportType={onChangeReportType}
                          onChangeReportPeriod={onChangeReportPeriod}
                          onChangeReportSortModel={onChangeReportOrdering}
                          onChangeReportPage={onChangeReportPage}
                          initialReportPeriodId={$state.params.periodId}
                          initialReportFrom={$state.params.from}
                          initialReportTo={$state.params.to}
                          sortModel={sortModel}
                          page={page}
                          goToDetails={goToDetailsPage}
                          goToBill={goToBillReport}
      />
    }
  </>
}

export default PaymentReportingAdapter;
