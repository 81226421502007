import {isBusinessGroup, isWhitelabel, MERP_COUNTRIES, MERP_USER_ROLES} from "../../../../../merp-helpers";

(function () {
  'use strict';
  angular.module('gf.menuConf', [])

    .run(function ($rootScope, MENU) {
      $rootScope.clientMenu = MENU;
    })

    .constant('MENU',
      [
        {
          translate: 'menu.dashboard',
          topmenu: 'dashboard',
          id: 'dashboard',
          access: function (security) {
            return security.hasAccess('/dashboard/');
          },
          submenu: [
            {
              translate: 'date.today',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '1'},
              id: '1'
            },
            {
              translate: 'date.yesterday',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '2'},
              id: '2'
            },
            {
              translate: 'date.thisWeek',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '3'},
              id: '3'
            },
            {
              translate: 'date.lastWeek',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '4'},
              id: '4'
            },
            {
              translate: 'date.thisMonth',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '5'},
              id: '5'
            },
            {
              translate: 'date.lastMonth',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '6'},
              id: '6'
            },
            {
              translate: 'date.FromTo',
              sref: 'page.dashboardDateType',
              stateParams: {dateType: '8'},
              id: '8',
              access: function (security) {
                return security.hasAccess('/dashboard/8');
              }
            },
            {
              translate: '',
              sref: 'page.dashboardEdit',
              id: 'edit',
              access: function (security) {
                return security.hasAccess('/dashboard/edit');
              },
              icon: 'fa fa-wrench'
            }
          ]
        },
        {
          translate: 'jsp.menu.masterdata',
          topmenu: 'masterdata',
          id: 'masterdata',
          popover: {
            text: "restaurantGroup.selected.warning",
            trigger: "{true: 'mouseenter', false: 'none'}[!!security.context.currentGroup]",
            placement: "bottom"
          },
          access: function (security) {
            return security.hasAccess('/masterdata/');
          },
          disable: function (security) {
            return security.context.currentGroup;
          },
          submenu: [
            {
              translate: 'article',
              sref: 'page.masterdataArticle',
              nav: ['page.masterdataArticle', 'page.masterdataArticleDetails', 'page.masterdataArticleAdd'],
              id: 'article'
            },
            {
              translate: 'inventory',
              sref: 'page.masterdataInventory',
              id: 'inventory',
              access: function (security) {
                return security.hasAccess('/masterdata/inventory');
              }
            },
            {
              translate: 'articleGroup',
              sref: 'page.masterdataArticle_group',
              nav: ['page.masterdataArticle_group', 'page.masterdataArticle_groupDetails', 'page.masterdataArticle_groupAdd'],
              id: 'article_group'
            },
            {
              translate: 'courseGroup',
              sref: 'page.masterdataCourse_group',
              nav: ['page.masterdataCourse_group', 'page.masterdataCourse_groupDetails', 'page.masterdataCourse_groupAdd'],
              id: 'course_group'
            },
            {
              translate: 'orderScreen',
              sref: 'page.masterdataOrder_screen',
              nav: ['page.masterdataOrder_screen', 'page.masterdataOrder_screenDetails', 'page.masterdataOrder_screenAdd'],
              id: 'order_screen'
            },
            {
              translate: 'waiter',
              sref: 'page.masterdataWaiter',
              nav: ['page.masterdataWaiter', 'page.masterdataWaiterDetails', 'page.masterdataWaiterAdd'],
              id: 'waiter'
            },
            {
              translate: 'ticketLayouts',
              sref: 'page.masterdataTicket_layout',
              nav: ['page.masterdataTicket_layout', 'page.masterdataTicket_layoutDetails', 'page.masterdataTicket_layoutAdd'],
              id: 'ticket_layout',
              access: function (security) {
                return security.hasAccess('/masterdata/ticket_layout');
              }
            },
            {
              translate: 'ticketLayout',
              sref: 'page.masterdataTicket_layoutCurrent',
              id: 'ticket_layout',
              access: function (security) {
                return security.hasAccess('/masterdata/ticket_layout/current');
              }
            },
            {
              translate: 'chooseRoom',
              sref: 'page.masterdataChoose_room',
              id: 'choose_room',
              access: function (security) {
                return security.hasAccess('/masterdata/choose_room');
              }
            }
          ]
        },
        {
          translate: 'jsp.menu.reporting',
          topmenu: 'report',
          id: 'reporting',
          access: function (security) {

            var showReports = security.hasAccess('/report/');
            var showFilters = security.hasAccess('/filter/') && !security.context.settings.enableLowEndMarket;
            var showBranch = security.hasAccess('/branch/') && !security.context.settings.enableLowEndMarket && security.hasAnyFeature('BRANCH_CONTROL');
            var showBranchFilters = security.hasAccess('/filter_branch/') && !security.context.settings.enableLowEndMarket && security.hasAnyFeature('BRANCH_CONTROL');

            var isNotMasterUnit = !security.hasAnyFeature('BRANCH_MANAGEMENT') ||
              _.isUndefined(security.context.masterUnit) ||
              security.context.masterUnit.id !== security.context.currentRestaurant.id;

            return showReports && isNotMasterUnit && !showFilters && !showBranch && !showBranchFilters;
          },
          submenu: [
            {
              translate: 'orderReports',
              sref: 'page.reportType',
              id: '1',
              stateParams: {typeId: "1"}
            },
            {
              translate: 'turnoverReports',
              sref: 'page.reportType',
              id: '2',
              stateParams: {typeId: "2"}
            },
            {
              translate: 'masterDataReports',
              sref: 'page.reportType',
              id: '6',
              stateParams: {typeId: "6"}
            },
            {
              translate: 'reviewReports',
              sref: 'page.reportType',
              id: '19',
              stateParams: {typeId: "19"},
              access: function (security) {
                return security.hasAccess('/report/19');
              }
            },
            {
              translate: 'corrections',
              sref: 'page.reportCorrectionsTransactionTypeDate',
              id: 'corrections',
              stateParams: {transactionType: "adjustment", date: "1"}
            },
            {
              translate: 'messageReports',
              sref: 'page.reportType',
              id: '3',
              stateParams: {typeId: "3"},
              access: function (security) {
                return security.hasAccess('/report/3');
              }
            },
            {
              translate: 'billReports',
              sref: 'page.reportType',
              id: '12',
              stateParams: {typeId: '12'}
            },
            {
              translate: 'datevReports',
              sref: 'page.reportType',
              id: '5',
              stateParams: {typeId: '5'},
              access: function (security) {
                return security.hasAccess('/report/5');
              },
              disable: function (security) {
                return !security.hasAnyFeature('DATEV');
              }
            },
            {
              translate: 'customer',
              sref: 'page.reportType',
              id: '13',
              stateParams: {typeId: '13'},
              access: function (security) {
                return security.hasAccess('/report/13');
              },
              disable: function (security) {
                return !security.hasAnyFeature('CUSTOMER');
              }
            },
            {
              translate: 'others',
              sref: 'page.reportType',
              id: '17',
              stateParams: {typeId: '17'},
              access: function (security) {
                return security.hasAccess('/report/17');
              },
              disable: function (security) {
                return !security.hasAnyFeature('TimeAndAttendance');
              }
            },
            {
              translate: 'report.queue',
              sref: 'page.reportRunning',
              id: 'running',
              access: function (security) {
                return security.hasAccess('/report/running');
              }
            },
            {
              translate: 'report.taxAudit',
              sref: 'page.taxAudit',
              id: 'taxAudit',
              access: function (security) {
                return security.hasAccess('/setup/gobd');
              }
            }
          ]
        },
        {
          translate: 'jsp.menu.reporting',
          topmenu: 'report',
          id: 'reporting',
          access: function (security) {
            var showReports = security.hasAccess('/report/');
            var showFilters = security.hasAccess('/filter/') && !security.context.settings.enableLowEndMarket;
            var showBranch = security.hasAccess('/branch/') && !security.context.settings.enableLowEndMarket && security.hasAnyFeature('BRANCH_CONTROL');
            var showBranchFilters = security.hasAccess('/filter_branch/') && !security.context.settings.enableLowEndMarket && security.hasAnyFeature('BRANCH_CONTROL');

            var isNotMasterUnit = !security.hasAnyFeature('BRANCH_MANAGEMENT') ||
              _.isUndefined(security.context.masterUnit) ||
              security.context.masterUnit.id !== security.context.currentRestaurant.id;

            return showReports && isNotMasterUnit && (showFilters || showBranch || showBranchFilters);
          },
          dropdown: [
            {
              translate: 'jsp.menu.reporting',
              topmenu: 'report',
              access: function (security) {
                return security.hasAccess('/report/');
              },
              submenu: [
                {
                  translate: 'orderReports',
                  sref: 'page.reportType',
                  id: '1',
                  stateParams: {typeId: '1'}
                },
                {
                  translate: 'turnoverReports',
                  sref: 'page.reportType',
                  stateParams: {typeId: '2'},
                  id: '2'
                },
                {
                  translate: 'masterDataReports',
                  sref: 'page.reportType',
                  stateParams: {typeId: '6'},
                  id: '6'
                },
                {
                  translate: 'reviewReports',
                  sref: 'page.reportType',
                  stateParams: {typeId: '19'},
                  id: '19',
                  access: function (security) {
                    return security.hasAccess('/report/19');
                  }
                },
                {
                  translate: 'corrections',
                  sref: 'page.reportCorrectionsTransactionTypeDate',
                  id: 'corrections',
                  stateParams: {transactionType: 'adjustment', date: '1'}
                },
                {
                  translate: 'messageReports',
                  sref: 'page.reportType',
                  id: '3',
                  stateParams: {typeId: '3'},
                  access: function (security) {
                    return security.hasAccess('/report/3');
                  }
                },
                {
                  translate: 'billReports',
                  sref: 'page.reportType',
                  id: '12',
                  stateParams: {typeId: '12'}
                },
                {
                  translate: 'datevReports',
                  sref: 'page.reportType',
                  id: '5',
                  stateParams: {typeId: '5'},
                  access: function (security) {
                    return security.hasAccess('/report/5');
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('DATEV');
                  }
                },
                {
                  translate: 'customer',
                  sref: 'page.reportType',
                  id: '13',
                  stateParams: {typeId: '13'},
                  access: function (security) {
                    return security.hasAccess('/report/13');
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('CUSTOMER');
                  }
                },
                {
                  translate: 'others',
                  sref: 'page.reportType',
                  id: '17',
                  stateParams: {typeId: '17'},
                  access: function (security) {
                    return security.hasAccess('/report/17');
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('TimeAndAttendance');
                  }
                },
                {
                  translate: 'report.queue',
                  sref: 'page.reportRunning',
                  id: 'running',
                  access: function (security) {
                    return security.hasAccess('/report/running');
                  }
                },
                {
                  translate: 'report.taxAudit',
                  sref: 'page.taxAudit',
                  id: 'taxAudit'
                }
              ]
            },
            {
              translate: 'jsp.menu.branch',
              topmenu: 'branch',
              access: function (security) {
                return security.hasAccess('/branch/') && !security.context.settings.enableLowEndMarket && security.hasAnyFeature('BRANCH_CONTROL');
              },
              submenu: [
                {
                  translate: 'orderReports',
                  sref: 'page.branchType',
                  id: '10',
                  stateParams: {typeId: '10'}
                },
                {
                  translate: 'turnoverReports',
                  sref: 'page.branchType',
                  id: '11',
                  stateParams: {typeId: '11'}
                },
                {
                  translate: 'datevReports',
                  sref: 'page.branchType',
                  id: '16',
                  stateParams: {typeId: '16'},
                  disable: function (security) {
                    return !security.hasAnyFeature('DATEV');
                  }
                },
                {
                  translate: 'customer',
                  sref: 'page.branchType',
                  id: '14',
                  stateParams: {typeId: '14'},
                  access: function (security) {
                    return security.hasAccess('/branch/14');
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('CUSTOMER');
                  }
                }
              ]
            },
            {
              translate: 'jsp.menu.reportFilters',
              topmenu: 'filter',
              access: function (security) {
                return security.hasAccess('/filter/') && !security.context.settings.enableLowEndMarket;
              },
              submenu: [
                {
                  translate: 'orderReportFilters',
                  sref: 'page.filterType',
                  nav: ['page.filterType', 'page.filterTypeDetails', 'page.filterTypeAdd'],
                  id: '1',
                  stateParams: {typeId: '1'}
                },
                {
                  translate: 'turnoverReportFilters',
                  sref: 'page.filterType',
                  nav: ['page.filterType', 'page.filterTypeDetails', 'page.filterTypeAdd'],
                  id: '2',
                  stateParams: {typeId: '2'}
                },
                {
                  translate: 'masterDataReportFilters',
                  sref: 'page.filterType',
                  nav: ['page.filterType', 'page.filterTypeDetails', 'page.filterTypeAdd'],
                  id: '6',
                  stateParams: {typeId: '6'}
                },
                // {
                //   translate: 'reviewReportFilters',
                //   sref: 'page.filterType',
                //   nav: '19',
                //   access: function (security) {
                //     return security.hasAccess('/report/19');
                //   }
                // },
                {
                  translate: 'messageReportFilters',
                  sref: 'page.filterType',
                  nav: ['page.filterType', 'page.filterTypeDetails', 'page.filterTypeAdd'],
                  id: '3',
                  stateParams: {typeId: '3'}
                },
                {
                  translate: 'customerFilters',
                  sref: 'page.filterType',
                  nav: ['page.filterType', 'page.filterTypeDetails', 'page.filterTypeAdd'],
                  id: '13',
                  stateParams: {typeId: '13'},
                  access: function (security) {
                    return security.hasAccess('/filter/13');
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('CUSTOMER');
                  }
                },
                {
                  translate: 'others',
                  sref: 'page.filterType',
                  nav: ['page.filterType', 'page.filterTypeDetails', 'page.filterTypeAdd'],
                  id: '17',
                  stateParams: {typeId: '17'}
                }
              ]
            },
            {
              translate: 'jsp.menu.branchFilters',
              topmenu: 'filter_branch',
              access: function (security) {
                return security.hasAccess('/filter_branch/') && !security.context.settings.enableLowEndMarket
                  && security.hasAnyFeature('BRANCH_CONTROL');
              },
              submenu: [
                {
                  translate: 'orderReportFilters',
                  sref: 'page.filter_branchType',
                  nav: ['page.filter_branchType', 'page.filter_branchTypeDetails', 'page.filter_branchTypeAdd'],
                  id: '10',
                  stateParams: {typeId: '10'}
                },
                {
                  translate: 'turnoverReportFilters',
                  sref: 'page.filter_branchType',
                  nav: ['page.filter_branchType', 'page.filter_branchTypeDetails', 'page.filter_branchTypeAdd'],
                  id: '11',
                  stateParams: {typeId: '11'}
                },
                {
                  translate: 'customerFilters',
                  sref: 'page.filter_branchType',
                  nav: ['page.filter_branchType', 'page.filter_branchTypeDetails', 'page.filter_branchTypeAdd'],
                  id: '14',
                  stateParams: {typeId: '14'},
                  access: function (security) {
                    return security.hasAccess('/filter_branch/14');
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('CUSTOMER');
                  }
                }
              ]
            },
            {
              translate: 'dynamicReporting',
              access: function (security) {
                return security.hasAccess('/dynamic_reporting/');
              },
              topmenu: 'dynamic_report',
              id: 'dynamicReporting',
              submenu: [
                {
                  translate: 'dynamicReporting',
                  sref: 'page.dynamicReporting',
                  nav: ['page.dynamicReporting'],
                  id: 'dynamicReporting'
                },
                {
                  translate: 'lsp.paymentReporting',
                  sref: 'page.paymentReporting',
                  nav: ['page.paymentReporting','page.paymentReportingSettlement'],
                  stateParams: {type: 'transactions'},
                  ignoreParamsWhenSelect: true,
                  id: 'paymentReporting',
                  access: function (security) {
                    return security.context.theme.name === 'lightspeed';
                  },
                  disable: function (security) {
                    return !security.hasAnyFeature('LIGHTSPEED_PAYMENTS');
                  }
                },
              ]
            }
          ]
        },
        {
          translate: 'jsp.menu.setup',
          topmenu: 'setup',
          popover: {
            text: "restaurantGroup.selected.warning",
            trigger: "{true: 'mouseenter', false: 'none'}[!!security.context.currentGroup]",
            placement: "bottom"
          },
          disable: function (security) {
            return security.context.currentGroup;
          },
          access: function (security) {
            return security.hasAccess('/setup/') || security.hasAccess('/hardware/') || security.hasAccess('/financial/') || security.hasAccess('/manual/') || security.hasAccess('/branch_management');
          },
          dropdown: [
            {
              translate: 'jsp.menu.setup',
              topmenu: 'setup',
              access: function (security) {
                return security.hasAccess('/setup/');
              },
              submenu: [
                {
                  translate: 'restaurant',
                  sref: 'page.setupRestaurant',
                  id: 'restaurant'
                },
                {
                  translate: 'waiterGroup',
                  sref: 'page.setupWaiter_group',
                  nav: ['page.setupWaiter_group', 'page.setupWaiter_groupDetails', 'page.setupWaiter_groupAdd'],
                  id: 'waiter_group',
                  access: function (security) {
                    return security.hasAccess('/setup/waiter_group');
                  }
                },
                {
                  translate: 'articleType',
                  sref: 'page.setupArticle_type',
                  nav: ['page.setupArticle_type', 'page.setupArticle_typeDetails', 'page.setupArticle_typeAdd'],
                  id: 'article_type',
                  access: function (security) {
                    return security.hasAccess('/setup/article_type');
                  }
                },
                {
                  translate: 'exchangeRate',
                  sref: 'page.setupExchange_rate',
                  id: 'exchange_rate',
                  access: function (security) {
                    return security.hasAccess('/setup/exchange_rate');
                  }
                },
                {
                  translate: 'rooms',
                  sref: 'page.setupRoom',
                  nav: ['page.setupRoom', 'page.setupRoomDetails', 'page.setupRoomAdd'],
                  id: 'room',
                  access: function (security) {
                    return security.hasAccess('/setup/room');
                  }
                },
                {
                  translate: 'restaurantTable',
                  sref: 'page.setupTable',
                  nav: ['page.setupTable', 'page.setupTableDetails', 'page.setupTableAdd'],
                  id: 'table',
                  access: function (security) {
                    return security.hasAccess('/setup/table');
                  }
                },
                {
                  translate: 'history',
                  sref: 'page.setupHistory',
                  nav: ['page.setupHistory', 'page.setupHistoryTabDomainId'],
                  id: 'history',
                  stateParams: {tab: "masterdata", domain: "article"},
                  ignoreParamsWhenSelect: true
                },
                {
                  translate: 'feature.RKSV',
                  sref: 'page.rksv.setupRksvCertificate',
                  nav: ['page.rksv.setupRksvCertificate', 'page.rksv.setupRksvGlobalTrust', 'page.rksv.setupRksvKeys', 'page.rksv.setupRksvDep'],
                  id: 'rksv',
                  access: function (security) {
                    return security.hasAccess('/setup/rksv');
                  }
                },
                {
                  translate: 'tse',
                  sref: 'page.setupTse',
                  nav: ['page.setupTse'],
                  id: 'tse',
                  access: function (security) {
                    return security.hasAccess('/setup/tse');
                  }
                },
                {
                  translate: 'companyInfo',
                  sref: 'page.companyInfo',
                  nav: ['page.companyInfo'],
                  id: 'companyInfo',
                  access: function (security) {
                    return security.hasAccess('/setup/companyInfo');
                  }
                }
              ]
            },
            {
              translate: 'jsp.menu.hardware',
              topmenu: 'hardware',
              access: function (security) {
                return security.hasAccess('/hardware/');
              },
              submenu: [
                {
                  translate: 'devices',
                  sref: 'page.hardwareDevice',
                  nav: ['page.hardwareDevice', 'page.hardwareDeviceDetails', 'page.hardwareDeviceAdd'],
                  id: 'device',
                  access: function (security) {
                    return security.hasAccess('/hardware/device');
                  }
                },
                {
                  translate: 'printers',
                  sref: 'page.printers.hardwarePrinter',
                  nav: ['page.printers.hardwarePrinter', 'page.printers.printerDefaultSettings', 'page.printers.hardwarePrinterAdd', 'page.printers.hardwarePrinterDetails'],
                  id: 'printer',
                  access: function (security) {
                    return security.hasAccess('/hardware/printer/printers_list');
                  }
                },
                {
                  translate: 'ticketLayouts',
                  sref: 'page.hardwareTicket_layout',
                  nav: ['page.hardwareTicket_layout', 'page.hardwareTicket_layoutDetails', 'page.hardwareTicket_layoutAdd'],
                  id: 'ticket_layout',
                  access: function (security) {
                    return security.hasAccess('/hardware/ticket_layout');
                  }
                },
                {
                  translate: 'paymentDevices',
                  sref: 'page.hardwarePaymentDevice',
                  nav: ['page.hardwarePaymentDevice', 'page.hardwarePaymentDeviceDetails', 'page.hardwarePaymentDeviceAdd'],
                  id: 'payment_device',
                  access: function (security) {
                    return security.hasAccess('/hardware/payment_device');
                  }
                }
              ]
            },
            {
              translate: 'jsp.menu.financial',
              topmenu: 'financial',
              access: function (security) {
                return security.hasAccess('/financial/');
              },
              submenu: [
                {
                  translate: 'settlementForms',
                  sref: 'page.financialSettlement_form',
                  nav: ['page.financialSettlement_form', 'page.financialSettlement_formDetails', 'page.financialSettlement_formAdd'],
                  id: 'settlement_form'
                },
                {
                  translate: 'taxes',
                  sref: 'page.financialTaxGrid',
                  nav: ['page.financialTaxGrid', 'page.financialTaxGridDetails', 'page.financialTaxGridAdd'],
                  id: 'tax',
                  access: function (security) {
                    return security.hasAccess('/financial/tax');
                  }
                },
                {
                  translate: 'vats',
                  sref: 'page.financialVat',
                  nav: ['page.financialVat', 'page.financialVatDetails', 'page.financialVatAdd'],
                  id: 'vat',
                  access: function (security) {
                    return security.hasAccess('/financial/vat');
                  }
                },
                {
                  translate: 'account',
                  sref: 'page.financialAccount',
                  nav: ['page.financialAccount', 'page.financialAccountDetails', 'page.financialAccountAdd'],
                  id: 'account',
                  access: function (security) {
                    return security.hasAccess('/financial/account');
                  }
                },
                {
                  translate: 'reasons',
                  sref: 'page.financialReasons',
                  nav: ['page.financialReasons', 'page.financialReasonsAdd'],
                  id: 'reasons',
                  access: function (security) {
                    return security.hasAccess('/financial/reasons');
                  }
                },
                {
                  translate: 'costCenters',
                  sref: 'page.financialCost_center',
                  nav: ['page.financialCost_center', 'page.financialCost_centerDetails', 'page.financialCost_centerAdd'],
                  id: 'cost_center',
                  access: function (security) {
                    return security.hasAccess('/financial/cost_center');
                  }
                },
                {
                  translate: 'discounts',
                  sref: 'page.financialDiscount',
                  nav: ['page.financialDiscount', 'page.financialDiscountDetails', 'page.financialDiscountAdd'],
                  id: 'discount'
                },
                {
                  translate: 'priceLevels',
                  sref: 'page.financialPrice_level',
                  nav: ['page.financialPrice_level', 'page.financialPrice_levelDetails', 'page.financialPrice_levelAdd'],
                  id: 'price_level'
                },
                {
                  translate: 'servicePeriods',
                  sref: 'page.financialService_period',
                  nav: ['page.financialService_period', 'page.financialService_periodDetails', 'page.financialService_periodAdd'],
                  id: 'service_period'
                },
                {
                  translate: 'datev',
                  sref: 'page.financialDatev',
                  id: 'datev',
                  access: function (security) {
                    return security.hasAccess('/financial/datev');
                  }
                },
                {
                  translate: 'getCubeAccount',
                  sref: 'page.financialCube',
                  id: 'cube',
                  access: function (security) {
                    return security.hasAccess('/financial/cube');
                  }
                },
                {
                  translate: 'corrections',
                  sref: 'page.financialCorrection',
                  id: 'correction',
                  access: function (security) {
                    return security.hasAccess('/financial/correction');
                  }
                },
                {
                  translate: 'invoices',
                  sref: 'page.financialInvoices',
                  id: 'invoices',
                  access: function (security) {
                    return security.hasAccess('/financial/invoices');
                  }
                }
              ]
            },
            {
              translate: 'menu.branchManagement',
              topmenu: 'branch_management',
              access: function (security) {
                return security.hasAccess('/branch_management');
              },
              submenu: [
                {
                  translate: 'menu.branchManagement',
                  sref: 'page.branchManagement',
                  id: 'branch'
                }
              ]
            },
            {
              translate: 'jsp.menu.manual',
              topmenu: 'manual',
              access: function (security) {
                return security.hasAccess('/manual/');
              },
              icon: 'fa fa-book',
              submenu: [
                {
                  translate: 'manual.cloud',
                  sref: 'page.manualType',
                  id: 'cloud',
                  stateParams: {manualType: "cloud"}
                },
                {
                  translate: 'manual.icash',
                  sref: 'page.manualType',
                  id: 'icash',
                  stateParams: {manualType: "icash"}
                },
                {
                  translate: 'manual.customer',
                  sref: 'page.manualType',
                  id: 'customer',
                  stateParams: {manualType: "customer"}
                }
              ]
            },
            {
              translate: 'jsp.menu.manual',
              topmenu: 'manual',
              access: function (security) {
                return security.hasAccess('/manual_link/');
              },
              icon: 'fa fa-book',
              submenu: [
                {
                  translate: 'jsp.menu.manual',
                  sref: 'page.manualLink',
                  id: 'manual_link'
                }
              ]
            }
          ]
        },
        {
          translate: 'jsp.menu.settings',
          topmenu: 'operator_data',
          access: function (security) {
            return security.hasAccess('/operator_data/') || security.hasAccess('/customer/') || security.hasAccess('/api_connection');
          },
          dropdown: [
            {
              translate: 'jsp.menu.settings',
              topmenu: 'operator_data',
              access: function (security) {
                return security.hasAccess('/operator_data/');
              },
              submenu: [
                {
                  translate: 'restaurant',
                  sref: 'page.operator_dataRestaurant',
                  nav: ['page.operator_dataRestaurant', 'page.operator_dataRestaurantDetails', 'page.operator_dataRestaurantAdd'],
                  id: 'restaurant'
                },
                {
                  translate: 'restaurantGroup',
                  sref: 'page.operator_dataRestaurant_group',
                  nav: ['page.operator_dataRestaurant_group', 'page.operator_dataRestaurant_groupDetails', 'page.operator_dataRestaurant_groupAdd'],
                  id: 'restaurant_group',
                  access: function (security) {
                    return security.hasAccess('/operator_data/restaurant_group');
                  }
                },
                {
                  translate: 'operatorUser',
                  sref: 'page.operator_dataUser',
                  nav: ['page.operator_dataUser', 'page.operator_dataUserDetails', 'page.operator_dataUserAdd'],
                  id: 'user'
                },
                {
                  translate: 'userGroup',
                  sref: 'page.operator_dataUser_group',
                  nav: ['page.operator_dataUser_group', 'page.operator_dataUser_groupDetails', 'page.operator_dataUser_groupAdd'],
                  id: 'user_group'
                },
                {
                  translate: 'operatorSettings',
                  sref: 'page.operator_dataOperator_settings',
                  id: 'operator_settings'
                },
                {
                  translate: 'trash',
                  sref: 'page.operator_dataTrash',
                  nav: ['page.operator_dataTrash', 'page.operator_dataTrashFilterFilterType', 'page.operator_dataTrashBranchFilterFilterType'],
                  id: 'trash',
                  stateParams: {tab: "masterdata", domain: "article"}
                },
                {
                  translate: 'controlRoom',
                  sref: 'page.operator_dataControl_room',
                  id: 'control_room',
                  access: function (security) {
                    return security.hasAccess('/operator_data/control_room');
                  }
                }
              ]
            },
            {
              translate: 'customer',
              topmenu: 'customer',
              access: function (security) {
                return security.hasAccess('/customer/');
              },
              disable: function (security) {
                return !security.hasAnyFeature('CUSTOMER');
              },
              submenu: [
                {
                  translate: 'customer',
                  sref: 'page.customerCustomer',
                  nav: ['page.customerCustomer', 'page.customerCustomerDetails', 'page.customerCustomerAdd'],
                  id: 'customer'
                },
                {
                  translate: 'customerGroup',
                  sref: 'page.customerCustomer_group',
                  nav: ['page.customerCustomer_group', 'page.customerCustomer_groupDetails', 'page.customerCustomer_groupAdd'],
                  id: 'customer_group'
                },
                {
                  translate: 'customerDiscount',
                  sref: 'page.customerDiscount',
                  nav: ['page.customerDiscount', 'page.customerDiscountDetails', 'page.customerDiscountAdd'],
                  id: 'discount'
                },
                {
                  translate: 'yoviteAccount',
                  sref: 'page.customerYovite',
                  id: 'yovite',
                  access: function (security) {
                    return security.hasAccess('/customer/yovite');
                  }
                }
              ]
            },
            {
              translate: 'openApi.connections',
              topmenu: 'api_connection',
              access: function (security) {
                return security.hasAccess('/api_connection');
              },
              submenu: [
                {
                  translate: 'openApi.connections',
                  sref: 'page.apiConnection',
                  nav: ['page.apiConnection', 'page.apiConnectionDetails', 'page.apiTariff'],
                  id: 'api_connection',
                  disable: function (security) {
                    return !security.hasAnyFeature('OPEN_API');
                  }
                },
                {
                  translate: 'openApi.forDevelopers',
                  sref: 'page.apiForDevelopers.start',
                  nav: ['page.apiForDevelopers.start', 'page.apiForDevelopers.faq', 'page.apiForDevelopers.developersTokens'],
                  id: 'api_for_developers',
                  disable: function (security) {
                    return !security.hasAnyFeature('OPEN_API');
                  }
                }
              ]
            }
          ]
        },
        {
          translate: 'financial.services',
          disable: function (security) {
            return isBusinessGroup(security);
          },
          access: function (security) {
            const whitelabel = isWhitelabel(security);

            if (whitelabel) {
              return false;
            }

            const lsFinancialEnabled = security?.context?.lsFinancialEnabled !== false;
            if (!lsFinancialEnabled) {
              return false;
            }

            const countryCode = security.context.contractType?.country?.code;
            const showMerchantPortalForCountry = countryCode && MERP_COUNTRIES.indexOf(countryCode) !== -1 || false;

            return security.hasAnyRole(...MERP_USER_ROLES) && showMerchantPortalForCountry;
          },
          nav: ['page.financialServices'],
          sref: 'page.financialServices',
          id: 'financialServices',
          submenu: [
            {
              sref: 'page.financialServices',
              disable: function() { return true; },
            }
          ]
        }
      ])
})();
