(function () {
  'use strict';
  angular.module('gf.operator.data.user', ['ui.router'])
    .factory('UserMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/user/metadata.json')
    })
    .factory('UserContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/user/context.json')
    })
    .factory('User', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/user/:userId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .config(function ($stateProvider) {

      $stateProvider
        .state('page.operator_dataUser', {
          url: '/operator_data/user',
          template: require('../template/operator-data/user/users.html'),
          controller: 'UserGridCtrl',
          controllerAs: 'userGridCtrl',
          resolve: {
            userMetadata: function (UserMetadata) {
              return UserMetadata.get().$promise;
            },
            userContext: function (UserContext) {
              return UserContext.get().$promise;
            }
          },
          title: "operatorUser"
        })

        .state('page.operator_dataUserAdd', {
          url: '/operator_data/user/add',
          template: require('../template/operator-data/user/user.html'),
          controller: 'UserAddCtrl',
          controllerAs: 'userCtrl',
          resolve: {
            userMetadata: function (UserMetadata) {
              return UserMetadata.get().$promise;
            },
            userContext: function (UserContext) {
              return UserContext.get().$promise;
            },
            user: function (User, $stateParams) {
              return User.load({userId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: "operatorUser"
        })

        .state('page.operator_dataUserDetails', {
          url: '/operator_data/user/:id',
          template: require('../template/operator-data/user/user.html'),
          controller: 'UserEditCtrl',
          controllerAs: 'userCtrl',
          resolve: {
            userMetadata: function (UserMetadata) {
              return UserMetadata.get().$promise;
            },
            userContext: function (UserContext) {
              return UserContext.get().$promise;
            },
            user: function (User, $stateParams) {
              return User.load({userId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: "operatorUser"
        })
    })
    .controller('UserChangePasswordCtrl',

      function ($scope, $uibModalInstance, $resource, $stateParams) {
        var ChangePassword = new $resource('/icash/operator_data/user/:userId/changePassword', {userId: $stateParams.id});

        $scope.changePassword = new ChangePassword({});
        $scope.passwordMetadata = {
          fields: {
            ps: {
              validators: [
                {
                  name: 'required',
                  message: 'error.password.required'
                }
              ]
            },
            psc: {
              validators: [
                {
                  name: 'required',
                  message: 'error.password.required'
                }
              ]
            }
          }};

        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          $scope.changePassword.$save(
            function (data) {
              if (data.payload.result) {
                $uibModalInstance.close(data.payload.user);
              } else {
                $uibModalInstance.close();
              }
            });
        }
      }
    )
    .factory('UserAdd', function ($location, $uibModal, $translate, $state, notifyManager, User) {
      return function (self, $scope, user, userContext, userMetadata) {
        self.userContext = userContext;
        self.userMetadata = userMetadata;
        self.user = user;
        angular.extend(self, userContext.data.dependencies);

        //add required to 'Email' field
        self.userMetadata.columns.email.angular = {'required': ''};
        self.userMetadata.columns.email.validation = ['required'];
        self.userMetadata.columns.email.errorMessages =
          [
            {
              'keys': ['required'],
              'message': 'org.hibernate.validator.constraints.NotBlank.message',
              'name': 'Required'
            }
          ];

        self.changePassword = function () {
          var modalDialog = $uibModal.open({
            template: require('../../../main/angular/app/template/page/change_password.html'),
            windowClass: 'gf-modal',
            controller: 'UserChangePasswordCtrl',
            controllerAs: 'userChangePasswordCtrl'
          });

          modalDialog.result.then(function (user) {
            if (user) {
              self.user = new User(user);
              //todo remove jQuery function, use lodash instead
              self.user.userGroupsIds = $.map(self.user.userGroups, function (v) {
                return v.id;
              });

              notifyManager.success($translate.instant('changePassword.success'));
            }
          });
        };

        self.save = function () {
          if (self.userForm.$valid) {
            self.user.$save(function () {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.go('page.operator_dataUser')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.userForm.$valid) {
            self.user.$save(function () {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.reload();
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
      }
    })
    .controller('UserAddCtrl',
      function ($scope, user, userContext, userMetadata, UserAdd) {
        var self = this;
        UserAdd(self, $scope, user, userContext, userMetadata);
      }
    )
    .controller('UserEditCtrl',
      function ($scope, $translate, notifyManager, $state, user, userContext, userMetadata, UserAdd) {

        var self = this;
        UserAdd(self, $scope, user, userContext, userMetadata);

        // Remove required from 'password' field
        self.userMetadata.columns.password.angular = {};
        self.userMetadata.columns.password.validation = [];

        self.save = function () {
          if (self.userForm.$valid) {
            self.user.$edit({userId: self.user.id}, function (response) {
              self.user = response.payload.entity;
              $state.go('page.operator_dataUser');

            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.userForm.$valid) {
            self.user.$edit({userId: self.user.id}, function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.operator_dataUserAdd')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )
    .controller('UserGridCtrl',
      function ($translate, userContext, userMetadata, securityService) {
        var self = this;
        self.metadata = userMetadata;
        self.context = userContext;

        var registeredActions = [
          {
            name: 'switchUser',
            template: 'app/template/grid/switch_user_action.html',
            controller: 'SwitchUserCtrl'
          }
        ];

        var actions = securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') ? ['activate', 'edit', 'switchUser', 'delete'] : ['activate', 'edit', 'delete'];

        self.config = {
          urlRoute: '/operator_data/user',
          registerActions: registeredActions,
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          cellEditableCondition: function ($scope) {
            return $scope.row.entity.email || !$scope.row.entity.id
          },
          columns: [
            {
              name: 'id', width: 80, enableCellEdit: false, visible: false,
              preFillValue: function (colDef, entity) {
                // using for pre-fill password
                if (!entity.password) {
                  entity.password = randomUUID();
                }
                return entity.id;
              }
            },
            {name: 'name', width: 166},
            {
              name: 'email', width: 200, cellEditableCondition: function ($scope) {
                return !$scope.row.entity.id;
              },
              cellTemplate: "<div uib-tooltip=\"{{(!row.entity.email && row.entity.id) ?  ('operatorUser.no.email.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>",
              cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                if (!row.entity.email && row.entity.id) {
                  return "warning"
                }

              },
              validations: {
                'gf-email': {
                  'angular': {'gf-uniqueoperatorname': ''},
                  'errorMessage': "org.hibernate.validator.constraints.Email.message"
                }
              }
            },
            {
              name: 'username', width: 166, visible: false,
              validations: {
                'gf-uniqueoperatorname': {
                  'angular': {'gf-uniqueoperatorname': ''},
                  'errorMessage': "de.icash.validator.UniqueUsername.message"
                }
              }
            },
            {name: 'userGroups', width: 166, multiselect: true},
            {name: 'language', width: 126},
            {
              name: 'accessibleRestaurants',
              width: 150,
              multiselect: true,
              cellFilter: 'multiselectFilter:5|UserAccessibleRestaurantsFilter:row.entity'
            },
            {name: 'currentRestaurant', width: 146, visible: false},
            {name: 'mailShiftOpen', width: 199, visible: false, displayName: 'user.mailShiftOpen'},
            {
              name: 'mailShiftClose',
              width: 292,
              visible: false,
              displayName: 'user.mailShiftClose'
            },
            {name: 'mailWeekly', width: 208, visible: false, displayName: 'user.mailWeekly'},
            {
              name: 'weeklyReport',
              width: 250,
              visible: false,
              displayName: 'user.weeklyReport',
              cellFilter: "UserReportNameFilter:grid.appScope.context.data.dependencies.reports|translate",
              editDropdownFilter: "UserReportListNameFilter|translate",
              filterCellFiltered: false,
              sortCellFiltered: false,
              editDropdownIdLabel: 'id',
              editDropdownValueLabel: 'group',
              editDropdownOptionsArray: self.context.data.dependencies.reports,
              editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownValueLabel] CUSTOM_FILTERS for field in editDropdownOptionsArray track by field[editDropdownIdLabel]\"></option></select></form></div>",
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.mailWeekly;
              }
            },
            {name: 'mailMonthly', width: 210, visible: false, displayName: 'user.mailMonthly'},
            {
              name: 'monthlyReport',
              width: 250,
              visible: false,
              displayName: 'user.monthlyReport',
              cellFilter: "UserReportNameFilter:grid.appScope.context.data.dependencies.reports|translate",
              editDropdownFilter: "UserReportListNameFilter|translate",
              filterCellFiltered: false,
              sortCellFiltered: false,
              editDropdownIdLabel: 'id',
              editDropdownValueLabel: 'group',
              editDropdownOptionsArray: self.context.data.dependencies.reports,
              editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownValueLabel] CUSTOM_FILTERS for field in editDropdownOptionsArray track by field[editDropdownIdLabel]\"></option></select></form></div>",
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.mailMonthly;
              }
            },
            {
              name: 'lastLogin',
              width: 146,
              cellFilter: "date:'short'|UserLastLoginFilter:row.entity",
              enableCellEdit: false
            },
            {name: 'countLogin', width: 160, enableCellEdit: false},
            {name: 'operate', actions: actions}
          ]
        };

        function randomUUID() {
          var i;
          var s = [], itoh = '0123456789ABCDEF';

          // Make array of random hex digits. The UUID only has 32 digits in it, but we
          // allocate an extra items to make room for the '-'s we'll be inserting.
          for (i = 0; i < 36; i++) s[i] = Math.floor(Math.random() * 0x10);

          // Conform to RFC-4122, section 4.4
          s[14] = 4;  // Set 4 high bits of time_high field to version
          s[19] = (s[19] & 0x3) | 0x8;  // Specify 2 high bits of clock sequence

          // Convert to hex chars
          for (i = 0; i < 36; i++) s[i] = itoh[s[i]];

          // Insert '-'s
          s[8] = s[13] = s[18] = s[23] = '-';

          return s.join('');
        }
      }
    )
    .controller('SwitchUserCtrl',
      function ($scope, $resource, $location, $http, SwitchUser, securityService, entity, $state) {
        var username = entity.email ? entity.email : entity.username;
        $http.get(SwitchUser, {params: {"username": username}}).then(function () {
          securityService.refreshContext().then(function () {
            $state.go("page.welcome");
          });
        });
      }
    )
    .filter('UserAccessibleRestaurantsFilter', ['$translate', function ($translate) {
      return function (input, entity) {
        if (!entity.id && !entity.filled) {
          return '';
        }
        return input ? input : $translate.instant('option.all');
      }
    }])
    .filter('UserLastLoginFilter', ['$translate', function ($translate) {
      return function (input, entity) {
        if (!entity.id) {
          return '';
        }
        return input ? input : $translate.instant('forms.temporal.never');
      }
    }])
    .filter('UserReportNameFilter', [function () {
      return function (input, reports) {
        return input ? _.find(reports, function (report) {
          return report.id == input.id;
        }).group.name : input;
      }
    }])
    .filter('UserReportListNameFilter', [function () {
      return function (input) {
        return input.name;
      }
    }])

})();
